<template>
      <div class="mt-16">
        <h2 class="sr-only">Recent orders</h2>
        <div class="max-w-7xl mx-auto sm:px-2 lg:px-8">
          <div class="max-w-2xl mx-auto space-y-8 sm:px-4 lg:max-w-4xl lg:px-0">
            <div class="bg-white border-t border-b border-gray-200 shadow-sm sm:rounded-lg sm:border">
              <h3 class="sr-only">
                Order placed on <time :datetime="order.created_at">{{ new Date(order.created_at).toLocaleDateString('en-US') }}</time>
              </h3>

              <div class="flex items-center p-4 border-b border-gray-200 sm:p-6 sm:grid sm:grid-cols-4 sm:gap-x-6">
                <dl class="flex-1 grid grid-cols-2 gap-x-6 text-sm sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                  <div>
                    <dt class="font-medium text-gray-900">Order number</dt>
                    <dd class="mt-1 text-gray-500">
                      {{ order.id }}
                    </dd>
                  </div>
                  <div class="hidden sm:block">
                    <dt class="font-medium text-gray-900">Date placed</dt>
                    <dd class="mt-1 text-gray-500">
                      <time :datetime="order.created_at">{{ new Date(order.created_at).toLocaleDateString('en-US') }}</time>
                    </dd>
                  </div>
                  <div>
                    <dt class="font-medium text-gray-900">Total amount</dt>
                    <dd class="mt-1 font-medium text-gray-900">
                      {{ order.paid_amount }}
                    </dd>
                  </div>
                </dl>
              </div>

              <h4 class="sr-only">Items</h4>
              <ul role="list" class="divide-y divide-gray-200">
                <li v-for="item in order.items" :key="item.product.id" class="p-4 sm:p-6">
                  <div class="flex items-center sm:items-start">
                    <div class="flex-shrink-0 w-20 h-20 bg-gray-200 rounded-lg overflow-hidden sm:w-40 sm:h-40">
                      <img :src="item.product.get_image" :alt="item.product.name" class="w-full h-full object-center object-cover" />
                    </div>
                    <div class="flex-1 ml-6 text-sm">
                      <div class="font-medium text-gray-900 sm:flex sm:justify-between">
                        <h5>
                          {{ item.product.name }}
                        </h5>
                        
                        <p class="mt-2 sm:mt-0">
                          {{ item.product.price }}
                        </p>
                      </div>
                      <div class="mt-1 flex text-sm">
                          <p class="text-gray-500">
                            {{ item.color }}
                          </p>
                          <p  class="ml-4 pl-4 border-l border-gray-200 text-gray-500">
                            {{ item.size }}
                          </p>
                        </div>
                      <p class="hidden text-gray-500 sm:block sm:mt-2">
                        {{ item.product.description }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

export default {
  name: 'OrderSummary',
  props: {
      order: Object
  },
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  methods: {
      getItemTotal(item) {
          return item.quantity * item.product.price
      },
      orderTotalLength(order) {
          return order.items.reduce((acc, curVal) => {
              return acc += curVal.quantity
          }, 0)
      },
      
  }
  
}
</script>