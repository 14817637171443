<template>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div>
            <hr>
            <div class="bg-white">
            <div class="py-16 sm:py-24">
                <div class="max-w-7xl mx-auto sm:px-2 lg:px-8">
                <div class="max-w-2xl mx-auto px-4 lg:max-w-4xl lg:px-0">
                <h1 class="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Order history</h1>
                <p class="mt-2 text-sm text-gray-500">Thank you for your purchases</p>
                </div>
            </div>
               <OrderSummary
                    v-for="order in orders"
                    v-bind:key="order.id"
                    v-bind:order="order" /> 
            </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import OrderSummary from '@/components/OrderSummary.vue'
export default {
    name: 'MyAccount',
    components: {
        OrderSummary
    },
    data() {
        return {
            orders: []
        }
    },
    mounted() {
        document.title = 'My account | BodiesByRich'
        this.getMyOrders()
    },
    methods: {
        logout() {
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            this.$store.commit('removeToken')
            this.$router.push('/')
        },
        async getMyOrders() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/orders/')
                .then(response => {
                    this.orders = response.data
                    console.log(this.orders)
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>